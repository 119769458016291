import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import './App.css';
import particlesOptions from './Components/particles.json';
import Main from './Components/main';

function App() {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  return (
    <div className="App">
      <Main />
      <Particles options={particlesOptions} init={particlesInit} />
      
    </div>
  );
}

export default App;
