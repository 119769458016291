const main = () => {
  return (
    <div className="container">
      {/* ///////////// START OF TITLE ///////////// */}
      <div className="bento  ">
        <div className=" row p-4 justify-content-md-center flex-nowrap box ">
          <img
            src={'images/title.png'}
            alt="SOMNIA"
            loading="lazy"
            width="200"
            class="som-hero"
          />
        </div>
      </div>

      <div className="bento ">
        <h1 className="som-title mb-4">INTRODUCTION</h1>
        <h2 className="som-text">Where dreams weave into realities, and every visit is a journey into the unknown.</h2>
        <p className="som-domain mt-3">
          <a href="mailto:somniamail@protonmail.com">somniamail@protonmail.com</a> for domain enquiries. 
        </p>
      </div>
    </div>
  );
};
export default main;
